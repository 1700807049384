<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-09-28 10:59:42
 * @LastEditTime: 2023-01-04 13:00:25
 * @Description: 订单申请
 * @Param:
 * @FilePath: \BusinessPlatform\src\views\productionManagement\orderManagement\orderApplication.vue
 -->

<template>
    <div class="orderApplication" v-loading="loading">
        <div class="pageContent" :class="{'fomeSee': isSee}">
            <div class="fomeStyle_dy">
                <table>
                    <tbody>
                        <tr>
                            <th>订单编号：</th>
                            <td>
                                <el-input v-model="formData.order_number" disabled placeholder="系统生成"></el-input>
                            </td>
                            <th>登记时间：</th>
                            <td>
                                <el-date-picker
                                    disabled
                                    v-model="formData.bill_time"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </td>
                        </tr>
                        <tr>
                            <th>创建人：</th>
                            <td>
                                <el-input v-model="formData.creator_name" disabled placeholder="请输入内容"></el-input>
                            </td>
                            <th>联系电话：</th>
                            <td>
                                <el-input v-model="formData.creator_phone" placeholder="请输入内容" :disabled="isSee"></el-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <thead>
                        <tr>
                            <th colspan="4">
                                <span>合同信息</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="iconfont iconbitian">
                                合同编号：
                            </th>
                            <td>
                                <el-input
                                    readonly
                                    placeholder="请选择合同"
                                    v-model="formData.contract_number"
                                    class="input-with-select"
                                    :disabled="isSee"
                                >
                                    <el-button
                                        slot="append"
                                        icon="iconfont icon009"
                                        @click="selectContract"
                                        v-if="!isSee"
                                    ></el-button>
                                </el-input>
                            </td>
                            <th class="iconfont iconbitian">
                                合同类型：
                            </th>
                            <td>
                                <el-input
                                    v-model="formData.contract_typename"
                                    readonly
                                    :disabled="isSee"
                                    placeholder="请选择合同"
                                ></el-input>
                            </td>
                        </tr>
                        <!-- <tr>
                            <th>销售方式：</th>
                            <td>
                                <el-input
                                    v-model="formData.sale_method_name"
                                    readonly
                                    :disabled="isSee"
                                    placeholder="请选择合同"
                                ></el-input>
                            </td>
                            <th>销售区域：</th>
                            <td>
                                <el-input
                                    v-model="formData.sale_area_name"
                                    readonly
                                    :disabled="isSee"
                                    placeholder="请选择合同"
                                ></el-input>
                            </td>
                        </tr> -->
                        <tr>
                            <th class="iconfont iconbitian">
                                客户名称：
                            </th>
                            <td>
                                <el-input
                                    v-model="formData.customer_name"
                                    readonly
                                    :disabled="isSee"
                                    placeholder="请输入内容"
                                ></el-input>
                            </td>
                            <th>统一社会信用代码或身份证：</th>
                            <td>
                                <el-input
                                    v-model="formData.customer_xydm"
                                    readonly
                                    :disabled="isSee"
                                    placeholder="请输入内容"
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="iconfont iconbitian">
                                工程名称：
                            </th>
                            <td>
                                <el-input
                                    v-model="formData.project_name"
                                    readonly
                                    :disabled="isSee"
                                    placeholder="请选择合同"
                                ></el-input>
                            </td>
                            <th class="iconfont iconbitian">
                                工程类型：
                            </th>
                            <td>
                                <el-input
                                    v-model="formData.project_type_name"
                                    readonly
                                    :disabled="isSee"
                                    placeholder="请选择合同"
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="iconfont iconbitian">
                                工程地址：
                            </th>
                            <td colspan="3">
                                <el-input
                                    v-model="formData.project_address"
                                    readonly
                                    :disabled="isSee"
                                    placeholder="请选择合同"
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="iconfont iconbitian">
                                当前收货地址：
                            </th>
                            <td colspan="3">
                                <el-input
                                    placeholder="请输入内容"
                                    v-model="formData.project_address1"
                                    :disabled="isSee"
                                    class="input-with-select"
                                >
                                    <el-button
                                        slot="append"
                                        class="iconfont iconlocation1"
                                        @click="openDrawFence"
                                        v-if="!isSee"
                                    ></el-button>
                                </el-input>
                            </td>
                        </tr>
                        <tr v-if="formData.construction_method == 2">
                            <th>施工单位：</th>
                            <td>
                                <el-input
                                    readonly
                                    placeholder="请选择施工单位"
                                    v-model="formData.sgdw_name"
                                    class="input-with-select"
                                    :disabled="isSee"
                                >
                                    <el-button
                                        slot="append"
                                        icon="iconfont icon009"
                                        @click="selectConstructionUnit"
                                        v-if="!isSee"
                                    ></el-button>
                                </el-input>
                            </td>
                            <th>统一社会信用代码：</th>
                            <td>
                                <el-input
                                    v-model="formData.sgdw_code"
                                    readonly
                                    :disabled="isSee"
                                    placeholder="请选择施工单位"
                                ></el-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <thead>
                        <tr>
                            <th colspan="4">
                                <span>工程信息</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="iconfont iconbitian">
                                强度等级：
                            </th>
                            <td>
                                <el-input
                                    readonly
                                    placeholder="请选择强度等级"
                                    v-model="formData.strength_grade_name"
                                    class="input-with-select"
                                    :disabled="isSee"
                                >
                                    <el-button
                                        slot="append"
                                        icon="iconfont icon009"
                                        @click="selectStrengthGradeColumns"
                                        v-if="!isSee"
                                    ></el-button>
                                </el-input>
                            </td>
                            <th>附加型号：</th>
                            <td>
                                <el-input
                                    readonly
                                    placeholder="请选择附加型号"
                                    v-model="formData.special_require_name"
                                    class="input-with-select"
                                    :disabled="isSee"
                                >
                                    <el-button
                                        slot="append"
                                        icon="iconfont icon009"
                                        @click="selectSpecialRequireList"
                                        v-if="!isSee"
                                    ></el-button>
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="iconfont iconbitian">
                                订货方量：
                            </th>
                            <td>
                                <el-input v-model="formData.quantity" placeholder="请输入内容" :disabled="isSee"></el-input>
                            </td>
                            <th class="iconfont iconbitian">
                                要求到货时间：
                            </th>
                            <td>
                                <el-date-picker
                                    v-model="formData.arrival_time"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :disabled="isSee"
                                >
                                </el-date-picker>
                            </td>
                        </tr>
                        <tr>
                            <th class="iconfont iconbitian">
                                运输方式：
                            </th>
                            <td>
                                <el-select
                                    v-model="formData.transport"
                                    placeholder="请选择运输方式"
                                    @change="selectTransportColumns"
                                    :disabled="isSee"
                                >
                                    <el-option
                                        v-for="item in transportColumns"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code"
                                    >
                                    </el-option>
                                </el-select>
                            </td>
                            <th class="iconfont iconbitian">
                                浇筑方式：
                            </th>
                            <td>
                                <el-select
                                    v-model="formData.pouring"
                                    placeholder="请选择浇筑方式"
                                    @change="selectPouringOriginal"
                                    :disabled="isSee"
                                >
                                    <el-option
                                        v-for="item in pouringOriginal"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code"
                                    >
                                    </el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <th>泵车类型：</th>
                            <td>
                                <el-select
                                    v-model="formData.pump_truck"
                                    placeholder="请选择泵车类型"
                                    @change="selectPumpTruckColumns"
                                    :disabled="isSee"
                                >
                                    <el-option
                                        v-for="item in pumpTruckColumns"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code"
                                    >
                                    </el-option>
                                </el-select>
                            </td>
                            <th class="iconfont iconbitian">
                                供货站点：
                            </th>
                            <td>
                                <el-select
                                    v-model="formData.station"
                                    placeholder="请选择供货站点"
                                    @change="selectSupplySite"
                                    :disabled="isSee"
                                >
                                    <el-option
                                        v-for="item in supplySite"
                                        :key="item.station_id"
                                        :label="item.name"
                                        :value="item.station_id"
                                    >
                                    </el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <th>坍落度：</th>
                            <td class="slump">
                                <el-input v-model="formData.slump" placeholder="请输入内容" :disabled="isSee"></el-input>
                                <span>—</span>
                                <el-input v-model="formData.slump_name" placeholder="请输入内容" :disabled="isSee"></el-input>
                            </td>
                            <th>运距：</th>
                            <td>
                                <el-input v-model="formData.haul_distance" placeholder="请输入内容" :disabled="isSee"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>工地要料人：</th>
                            <td>
                                <el-input
                                    readonly
                                    placeholder="请选择工地要料人"
                                    v-model="formData.gdylr"
                                    class="input-with-select"
                                    :disabled="isSee"
                                >
                                    <el-button
                                        slot="append"
                                        icon="iconfont icon009"
                                        @click="selectMaterialPerson"
                                        v-if="!isSee"
                                    ></el-button>
                                </el-input>
                            </td>
                            <th>联系电话：</th>
                            <td>
                                <el-input
                                    v-model="formData.gdylr_phone"
                                    readonly
                                    placeholder="请选择工地要料人"
                                    :disabled="isSee"
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>现场联系人：</th>
                            <td>
                                <el-input
                                    placeholder="请输入内容"
                                    v-model="formData.xclxr"
                                    class="input-with-select"
                                    :disabled="isSee"
                                ></el-input>
                            </td>
                            <th>联系电话：</th>
                            <td>
                                <el-input v-model="formData.xclxr_phone" placeholder="请输入内容" :disabled="isSee"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>工地限高(米)：</th>
                            <td>
                                <el-input v-model="formData.limited_height" placeholder="请输入内容" :disabled="isSee"></el-input>
                            </td>
                            <th>工地限方：</th>
                            <td>
                                <el-input v-model="formData.limited_volume" placeholder="请输入内容" :disabled="isSee"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>工程描述：</th>
                            <td colspan="3">
                                <el-input
                                    type="textarea"
                                    placeholder="请输入工程描述"
                                    v-model="formData.project_detail"
                                    :disabled="isSee"
                                >
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>备注：</th>
                            <td colspan="3">
                                <el-input
                                    type="textarea"
                                    placeholder="请输入内容"
                                    v-model="formData.remarks"
                                    :disabled="isSee"
                                >
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>价格：</th>
                            <td colspan="3">
                                <el-input v-model="formData.price_remark" disabled></el-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <footer v-if="!isSee">
            <el-button type="primary" @click="saveMonomer" :loading="saveLoading">
                保存
            </el-button>
            <el-button @click="close">
                取消
            </el-button>
        </footer>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    name: 'order-application',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            saveLoading: false,
            formData: {
                order_number: '',
                bill_time: '',
                is_dealer: 0,
                dealer_code: '',
                contract_number: '',
                contract_version: '',
                contract_type: '',
                contract_typename: '',
                is_special_contract: 0,
                employee_code: '',
                employee_name: '',
                employee_phone: '',
                dept_id: '',
                company_id: '',
                customer_code: '',
                customer_name: '',
                customer_type: '',
                customer_xydm: '',
                sale_area_id: '',
                sale_area_name: '',
                sale_method_code: '',
                gfdw_code: '',
                gfdw_name: '',
                sale_method_name: '',
                sgdw_code: '',
                sgdw_name: '',
                project_code: '',
                project_name: '',
                project_detail: '',
                project_type: '',
                project_type_name: '',
                project_address: '',
                project_longitude: '',
                project_latitude: '',
                project_address1: '',
                project_longitude1: '',
                project_latitude1: '',
                supply_site: '',
                supply_site_name: '',
                station: '',
                station_name: '',
                haul_distance: 0,
                quantity: 0,
                arrival_time: '',
                strength_grade: '',
                strength_grade_name: '',
                special_require: '',
                special_require_name: '',
                price: 0,
                monomer: '',
                monomer_name: '',
                ground_floors: 0,
                underground_floors: 0,
                cons_floor: 0,
                place: '',
                place_name: '',
                place_category: '',
                place_category_name: '',
                place_detail: '',
                slump: '',
                slump_name: '',
                transport: '',
                transport_name: '',
                pouring: '',
                pouring_name: '',
                pump_truck: '',
                pump_truck_name: '',
                gdylr: '',
                gdylr_phone: '',
                xclxr: '',
                xclxr_phone: '',
                status: 0,
                submitted: '',
                remarks: '',
                creator: '',
                created: '',
                sort_num: 0,
                auto_index: 0,
                year_index: '',
                creator_name: '',
                creator_phone: '',
                limited_height: 0,
                limited_volume: 0,
                order_type: 0,
                waiter_id: '',
                waiter_name: '',
                sgdw_id: '',
                construction_method: 0,
                section: '',
                section_id: '',
                is_specify_monomer: true,
                specification_mode_name: '',
                info_price_additional_model_code: '',
                info_price_additional_model: '',
                info_price_pouring: '',
                price_remark: '',
                settlement_type: 0,
            },
            // 必填项
            formRequired: {
                contract_number: {
                    message: '请选择合同',
                },
                strength_grade: {
                    message: '请选择强度等级',
                },
                quantity: {
                    message: '请输入订货方量',
                },
                arrival_time: {
                    message: '请选择要求到货时间',
                },
                transport: {
                    message: '请选择运输方式',
                },
                pouring: {
                    message: '请选择浇筑方式',
                },
                station: {
                    message: '请选择供货站点',
                },
            },
            result: [],
            fence_point: '',
            associated: false,
            // 强度等级下拉数据
            strengthGradeColumns: [],
            // 附加型号下拉数据
            specialRequireList: [],
            // 运输方式下拉数据
            transportColumns: [],
            // 浇筑方式下拉数据
            pouringOriginal: [],
            // 泵车类型下拉数据
            pumpTruckColumns: [],
            // 供货站点下拉数据
            supplySite: [],
            // 选择合同是否关联经销商合同
            isRelatedContract: false,
            // 查看场景
            isSee: false,
        };
    },
    computed: {},
    watch: {},
    created() {
        if (this.extr?.isSee) {
            this.isSee = true;
        }
    },
    mounted() {
        this.initPage();
    },
    destroyed() {},
    methods: {
        async initPage() {
            this.loading = true;
            if (!this.formData.poid) {
                this.formData.order_number = await this.getAutoCode();
            }

            // 获取-运输方式
            this.transportColumns = await this.getPlaceCategory('304-YS0');
            if (this.transportColumns.length > 0) {
                this.formData.transport = this.transportColumns[1].code;
                this.formData.transport_name = this.transportColumns[1].name;// 默认第二个
            }

            // 获取-浇筑方式
            this.pouringOriginal = await this.getPlaceCategory('302-JZ0');

            // 获取-泵车类型
            this.pumpTruckColumns = await this.getPlaceCategory('303-BS0');

            // 获取-供应站点
            this.supplySite = await this.getStationList();


            if (this.formData.sale_method_code === '2') {
                this.getAffiliatedDealers().then( isAffiliatedDealers => {
                    if (isAffiliatedDealers === 'ok') {
                        this.isRelatedContract = true;
                    }
                });
            } else {
                this.isRelatedContract = true;
            }
            // 编辑、查看 初始化数据
            if (this.extr?.type === 'editOrder') {
                this.formData = await this.getInitFormData(this.extr.order_number);
            }
            // 复制新增 初始化数据
            if (this.extr?.type === 'copyOrder') {
                const oldData = await this.getInitFormData(this.extr.order_number);
                const data = await this.getContractByNumber(this.extr.contract_number);
                oldData.status = 0;
                oldData.poid = null;
                oldData.quantity = '';
                oldData.arrival_time = '';
                oldData.order_number = await this.getAutoCode();
                oldData.employee_code = data.salesman_id;
                oldData.employee_name = data.salesman_name;
                oldData.employee_phone = data.salesman_phone;
                this.formData = oldData;
            }

            if (this.extr?.type === 'newOrder' || this.extr?.type === 'copyOrder') {
                this.formData.bill_time = util.currentTimeFormat('yyyy-MM-dd HH:mm:ss');
                this.formData.creator_name = this.$takeTokenParameters('Name');
                this.formData.creator_phone = this.$takeTokenParameters('MobilePhone');
            }

            if (this.formData.contract_number) {
                // 强度等级下拉数据
                this.strengthGradeColumns = await this.getStrengthGrade();
            }
            if (this.formData.strength_grade) {
                // 附加型号下拉数据
                this.specialRequireList = await this.getAdditional(this.formData.strength_grade);
            }
            this.loading = false;
        },
        close() {
            this.$parent.hide();
        },
        // 选择合同
        selectContract() {
            this.$toast({
                title: true,
                type: 'eject',
                width: '70%',
                height: '78%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'choose_contract',
                    },
                    selectContractCallback: this.confirmContract,
                },
            });
        },
        // 确认选择合同
        async confirmContract(data) {
            this.formData.construction_method = data.construction_method;
            this.formData.contract_number = data.contract_number;
            this.formData.contract_type = data.contract_type;
            this.formData.contract_typename = data.contract_type_name;
            this.formData.contract_version = data.cvid;
            this.formData.fence_point = data.fence_point;
            this.formData.is_specify_monomer = Boolean(data.is_specify_monomer);
            this.formData.project_code = data.engineering_number;
            this.formData.project_name = data.engineering_name;
            this.formData.project_type = data.engineering_type;
            this.formData.project_type_name = data.engineering_type_name;
            this.formData.project_address = data.engineering_location;
            this.formData.project_longitude = data.x_coordinate_point;
            this.formData.project_latitude = data.y_coordinate_point;
            this.formData.project_address1 = data.engineering_location;
            this.formData.project_longitude1 = data.x_coordinate_point;
            this.formData.project_latitude1 = data.y_coordinate_point;
            this.formData.station = data.supply_site;
            this.formData.station_name = data.supply_site_name;
            this.formData.customer_code = data.client_id;
            this.formData.customer_name = data.client_name;
            this.formData.customer_type = data.client_type;
            this.formData.customer_xydm = data.client_uscc;
            this.formData.sgdw_code = data.construction_uscc;
            this.formData.sgdw_name = data.construction_enterprise;
            this.formData.dept_id = data.sales_dept_id;
            this.formData.company_id = data.company_id;
            this.formData.branch_id = data.branch_id;
            this.formData.employee_code = data.salesman_id;
            this.formData.employee_name = data.salesman_name;
            this.formData.employee_phone = data.salesman_phone;
            this.formData.haul_distance = data.haul_distance;
            this.formData.WantMaterialList = data.WantMaterialList;

            this.formData.is_dealer = data.is_dealer || 0;
            this.formData.dealer_code = data.dealer_code;
            this.formData.sale_area_id = data.sale_area_id;
            this.formData.sale_area_name = data.sale_area_name;
            this.formData.sale_method_code = data.sale_method_code + '';
            this.formData.sale_method_name = data.sale_method_name;

            this.formData.order_type = data.order_type;
            this.formData.waiter_id = data.waiter_id;
            this.formData.waiter_name = data.waiter_name;

            this.formData.is_special_contract = data.is_special_contract;
            this.formData.gfdw_code = data.gfdw_code;
            this.formData.gfdw_name = data.gfdw_name;

            this.constructionFloor = [];
            this.formData.cons_floor = '';
            this.formData.ground_floors = 0;
            this.formData.underground_floors = 0;
            this.formData.monomer = '';
            this.formData.monomer_name = '';
            this.formData.place = '';
            this.formData.place_name = '';
            this.constructionPosition = [];
            this.formData.place = '';
            this.formData.place_name = '';
            this.formData.place_category = '';
            this.formData.place_category_name = '';

            // 强度等级下拉数据
            this.strengthGradeColumns = await this.getStrengthGrade();
        },

        // 当前收货地址定位
        openDrawFence() {
            const x = this.formData.project_longitude1;
            const y = this.formData.project_latitude1;
            const address = this.formData.project_address1;
            this.$toast({
                title: true,
                text: '当前收货地址',
                type: 'eject',
                width: '70%',
                height: '78%',
                t_url: 'productionManagement/logisticsMonitoring/drawElectronicFence',
                extr: {
                    closeBtn: {},
                    position: [x, y],
                    fence_point: this.fence_point,
                    address: address,
                    sureCallback: this.drawCallBack,
                    onlyPoint: true,
                },
            });
        },

        // 当前收货地址选择回调
        drawCallBack(overlays, position, address) {
            this.formData.project_address1 = address;
            this.formData.project_longitude1 = position[0];
            this.formData.project_latitude1 = position[1];
        },

        // 选择施工单位
        selectConstructionUnit() {
            if (this.formData.construction_method === 2) {
                if (this.formData.contract_version === '') {
                    parent.$globalHub.$message.warning('没有工程信息，请先选择合同。');
                    return;
                }
                this.beforeOpenTablePage('order_select_construction', this.ConstructionUnitCallback);
            }
        },

        // 选择施工单位回调
        ConstructionUnitCallback(data) {
            this.formData.sgdw_id = data.ecid;
            this.formData.sgdw_code = data.construction_uscc;
            this.formData.sgdw_name = data.enterprise_name;

            this.formData.ground_floors = 0;
            this.formData.underground_floors = 0;
            this.formData.monomer = '';
            this.formData.monomer_name = '';
        },
        beforeOpening(TableCode, callbackFun) {
            if (this.formData.construction_method === 2) {
                if (this.formData.contract_version === '') {
                    this.$message.warning('没有工程信息，请先选择合同。');
                    return;
                }
                if (['order_select_monomer', 'order_select_want_material'].indexOf(TableCode) > -1) {
                    if ((this.formData.sgdw_id || '') === '') {
                        this.$message.warning('请先选择施工单位');
                        return;
                    }
                }
                this.beforeOpenTablePage(TableCode, callbackFun);
            }
        },

        // 打开选择列表
        beforeOpenTablePage(TableCode, CallbackFun) {
            const contract_version_id = this.formData.contract_version;
            const construction_id = this.formData.sgdw_id;
            const bid_section_id = this.formData.section_id;
            const queryString = `contract_version_id=${contract_version_id}&construction_id=${construction_id}&bid_section_id=${bid_section_id}`;
            this.$toast({
                title: true,
                type: 'eject',
                width: '70%',
                height: '78%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: TableCode,
                        QueryString: queryString,
                    },
                    selectCallback: CallbackFun,
                },
            });
        },
        // 选择强度等级
        async selectStrengthGradeColumns() {
            this.$toast({
                title: true,
                type: 'eject',
                width: '70%',
                height: '70%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'order_choose_qddj',
                    },
                    sureCallback: this.chooseStrengthGradeCallBack,
                },
            });
            // 附加型号下拉数据
            // this.specialRequireList = await this.getAdditional(this.formData.strength_grade);
        },

        // 选择强度等级回调
        chooseStrengthGradeCallBack(obj) {
            this.formData.strength_grade = obj.product_mark_code;
            this.formData.strength_grade_name = obj.product_mark_num;
            // this.formData.specification_mode_name = obj.specification_mode_name;
            // this.formData.info_price_additional_model_code = obj.additional_model_code;
            // this.formData.info_price_additional_model = obj.additional_model;
            // this.formData.special_require_name = obj.additional_model;
            // this.formData.special_require = obj.additional_model_code;
            // this.formData.info_price_pouring = obj.pouring;
            // this.formData.price = obj.product_mark_price;
            // this.formData.price_remark = `${obj.specification_mode_name}：${obj.product_mark_price}（元）`;
        },

        // 选择附加型号
        selectSpecialRequireList() {
            if ((this.formData.contract_number || '') === '') {
                this.$message({
                    showClose: true,
                    message: '请先选择合同。',
                    type: 'warning',
                });
                return;
            }
            if ((this.formData.strength_grade || '') === '') {
                this.$message({
                    showClose: true,
                    message: '请先选强度等级。',
                    type: 'warning',
                });
                return;
            }
            const queryString = 'contract_number=' + this.formData.contract_number + '&fjxh=' + this.formData.strength_grade;
            if (this.formData.contract_type === '102-LS2') {
                this.$toast({
                    title: true,
                    type: 'eject',
                    width: '70%',
                    height: '78%',
                    t_url: 'tableList/index',
                    extr: {
                        code: {
                            TableCode: 'fjxh',
                            QueryString: queryString,
                        },
                        sureCallback: this.chooseSpecialRequireCallBack,
                    },
                });
            } else {
                this.$toast({
                    title: true,
                    type: 'eject',
                    width: '70%',
                    height: '78%',
                    t_url: 'tableList/index',
                    extr: {
                        code: {
                            TableCode: 'chose_contract_fjxh',
                            QueryString: queryString,
                        },
                        sureCallback: this.chooseSpecialRequireCallBack,
                    },
                });
            }
        },
        // 选择附加型号回调
        chooseSpecialRequireCallBack(row) {
            this.formData.special_require_name = '';
            this.formData.special_require = '';
            const newCode = row.map(item => item.additional_model_code).join('|');
            const newName = row.map(item => item.additional_model).join(',');
            let price = 0;
            row.forEach(v => {
                price += v.additional_model_price;
            });
            this.formData.price_remark = `${newName}：${price}（元）`;
            if (this.formData.info_price_additional_model) {
                this.formData.special_require_name = `${this.formData.info_price_additional_model},${newName}`;
            } else {
                this.formData.special_require_name = newName;
            }
            if (this.formData.info_price_additional_model_code) {
                this.formData.special_require = `${this.formData.info_price_additional_model_code}|${newCode}`;
            } else {
                this.formData.special_require = newCode;
            }
        },

        // 选择运输方式
        selectTransportColumns() {
            const row = this.transportColumns.filter(item => item.code === this.formData.transport);
            this.formData.transport_name = row[0].name;
        },

        // 选择浇筑方式
        selectPouringOriginal() {
            const row = this.pouringOriginal.filter(item => item.code === this.formData.pouring);
            this.formData.pouring_name = row[0].name;
        },

        // 选择泵车类型
        selectPumpTruckColumns() {
            const row = this.pumpTruckColumns.filter(item => item.code === this.formData.pump_truck);
            this.formData.pump_truck_name = row[0].name;
        },

        // 选择供货站点
        selectSupplySite() {
            const row = this.supplySite.filter(item => item.code === this.formData.station_id);
            this.formData.pump_truck_name = row[0].name;
        },

        // 选择工地要料人
        async selectMaterialPerson() {
            if (this.formData.contract_version === '') {
                this.$message.warning('请先选择合同。');
                return;
            }
            if (this.formData.construction_method === 2) {
                this.beforeOpening('order_select_want_material', this.selectMaterialPersonCallbackFun);
            } else {
                let queryString = 'cvid=' + this.formData.contract_version;
                if (!this.formData.is_specify_monomer) {
                    queryString += queryString + '&engineering_number=' + this.formData.project_code;
                    this.$toast({
                        title: true,
                        type: 'eject',
                        width: '70%',
                        height: '78%',
                        t_url: 'tableList/index',
                        extr: {
                            code: {
                                TableCode: 'chose_engineeing_ylr',
                                QueryString: queryString,
                            },
                            selectCallback: this.selectMaterialPersonCallbackFun,
                        },
                    });
                } else {
                    const res = await this.getSiteMaterialBuyer();
                    if (res.length === 0) {
                        queryString += queryString + '&engineering_number=' + this.formData.project_code;
                        this.$toast({
                            title: true,
                            type: 'eject',
                            width: '70%',
                            height: '78%',
                            t_url: 'tableList/index',
                            extr: {
                                code: {
                                    TableCode: 'chose_engineeing_ylr',
                                    QueryString: queryString,
                                },
                                selectCallback: this.selectMaterialPersonCallbackFun,
                            },
                        });
                    } else {
                        queryString += queryString + '&monomer_number=' + this.formData.monomer;
                        this.$toast({
                            title: true,
                            type: 'eject',
                            width: '70%',
                            height: '78%',
                            t_url: 'tableList/index',
                            extr: {
                                code: {
                                    TableCode: 'chose_monomer_ylr',
                                    QueryString: queryString,
                                },
                                selectCallback: this.selectMaterialPersonCallbackFun,
                            },
                        });
                    }
                }
            }
        },

        // 选择工地要料人回调
        selectMaterialPersonCallbackFun(data) {
            this.formData.gdylr = data.want_material_name;
            this.formData.gdylr_phone = data.want_material_phone;
        },

        // 保存
        async saveMonomer() {
            const results = this.requiredValidation();
            if (!results) {return;}
            this.saveLoading = true;
            if (this.isRelatedContract === false) {
                this.$message.warning('请先关联经销商合同!');
                this.saveLoading = false;
                return;
            }
            let isInRing = false;
            if (this.formData.fence_point) {
                const arr = this.formData.fence_point.split('|');
                const polygonPath = [];
                arr.map(item => {
                    const lngLat = item.split(',');
                    const obj = [lngLat[0], lngLat[1]];
                    polygonPath.push(obj);
                });
                // eslint-disable-next-line no-undef
                const inRing = AMap.GeometryUtil.isPointInRing([this.formData.project_longitude1, this.formData.project_latitude1], polygonPath);
                isInRing = inRing;
            } else {
                isInRing = true;
            }

            if (!isInRing) {
                this.$message.warning('当前收货地址不在电子围栏范围内，请重新标注收货地址!');
                this.saveLoading = false;
                return;
            }

            if (!this.formData.project_longitude1 || !this.formData.project_latitude1) {
                this.$message.warning('当前收货地址经纬度不能为空!');
                this.saveLoading = false;
                return;
            }
            if (!this.formData.project_longitude1 || !this.formData.project_latitude1) {
                this.$message.warning('工程地址数据异常，请先维护工程地址!');
                this.saveLoading = false;
                return;
            }
            if (this.formData.sale_method_code === '2') {
                const isAffiliatedDealers = await this.getAffiliatedDealers();
                if (isAffiliatedDealers !== 'ok') {
                    return;
                }
            }
            this.formData.project_latitude1 = this.formData.project_latitude1 + '';
            this.formData.project_longitude1 = this.formData.project_longitude1 + '';
            const res = await this.getContractClose();
            if (!res) {
                const formData = JSON.parse(JSON.stringify(this.formData));
                if (formData.cons_floor) {
                    formData.cons_floor = Number(formData.cons_floor);
                } else {
                    formData.cons_floor = 0;
                }
                if (this.formData.poid) {
                    this.$axios
                        .post('/interfaceApi/production/productorder/modify', formData)
                        .then(() => {
                            this.$message({
                                message: '修改成功',
                                type: 'success',
                            });
                            if (this.extr?._table) {
                                this.extr._table.updateTable();
                            }
                        }).catch(error => {
                            this.saveLoading = false;
                            this.$message.error(error.ErrorCode.Message);
                        });
                } else {
                    this.$axios
                        .post('/interfaceApi/production/productorder/add', formData)
                        .then(async res => {
                            if (res) {
                                if (this.extr?.pageName === 'dispatch') {
                                    const confirmeResult = await this.submitandconfirme(this.formData.order_number);
                                    if (confirmeResult.ResultCode === 1) {
                                        this.$message({
                                            message: '新建订单成功！',
                                            type: 'success',
                                        });
                                        if (this.extr?._table) {
                                            this.extr._table.updateTable();
                                        }
                                        this.$parent.hide();
                                    } else {
                                        this.$alert(res.Description, '管控提醒', {
                                            type: 'warning',
                                            dangerouslyUseHTMLString: true,
                                        });
                                    }
                                } else {
                                    this.$message({
                                        message: '保存成功',
                                        type: 'success',
                                    });
                                    if (this.extr?._table) {
                                        this.extr._table.updateTable();
                                    }
                                    this.$parent.hide();
                                }
                            }
                        }).catch(error => {
                            this.saveLoading = false;
                            this.$message.error(error.ErrorCode.Message);
                        });
                }
            }
            this.saveLoading = false;
        },

        // 编辑查看初始化数据
        getInitFormData(order_number) {
            return this.$axios.$get(`/interfaceApi/production/productorder/number/${order_number}`, { defEx: true });
        },

        // 复制新增拉取合同最新数据
        getContractByNumber(contract_number) {
            const url = `/interfaceApi/sale/contract_manager/get_contract_by_number?contractNumber=${contract_number}`;
            return this.$axios.$get(url, { defEx: true });
        },

        // 获取下拉框数据列表
        getPlaceCategory(parentCode) {
            return this.$axios.$get(`/interfaceApi/baseinfo/dictionary_config/getlist?parent_code=${parentCode}`, { defEx: true });
        },

        // 获取强度等级
        getStrengthGrade() {
            const url = `/interfaceApi/sale/contract_manager/get_strength_grade?contractNumber=${this.formData.contract_number}`;
            return this.$axios.$get(url, { defEx: true });
        },

        // 获取附加型号
        getAdditional(markcode) {
            const url = `/interfaceApi/sale/contract_manager/get_additional?contractNumber=${this.formData.contract_number}&markcode=${markcode}`;
            return this.$axios.$get(url, { defEx: true });
        },

        // 获取供货站点
        getStationList() {
            const queryStr = '?companyId=' + this.$takeTokenParameters('CompanyId') + '&branchId=' + this.$takeTokenParameters('BranchId');
            return this.$axios.$get(`/interfaceApi/baseinfo/stationmanger/get_station_list${queryStr}`, { defEx: true });
        },

        // 获取工地要料人
        getSiteMaterialBuyer() {
            return this.$axios.$get(`/interfaceApi/sale/want_materia_person/monomer_by_number/${this.formData.monomer}`, { defEx: true });
        },

        // 拉取合同是否关联经销商
        getAffiliatedDealers() {
            const url = `/interfaceApi/sale/contract_distributor/is_related_distribution_contract/${this.formData.contract_number}`;
            return this.$axios.$get(url, { defEx: true });
        },

        // 拉取合同是否已经关闭
        getContractClose() {
            return this.$axios.$post('/interfaceApi/sale/contract_manager/is_contract_close_by_number/'
            + this.formData.contract_number, { defEx: true });
        },

        // 通过code拉取字典信息
        getbycode(num) {
            return this.$axios.get('/interfaceApi/baseinfo/dictionary_config/getbycode/' + num);
        },

        // 初始化订单编号
        getAutoCode() {
            const currentDate = util.currentTimeFormat('yyyy-MM-dd');
            return this.$axios.get(`/interfaceApi/baseinfo/dictionary_config/getno_custom?code=P${currentDate.split('-').join('')}`);
        },

        // 提交确认
        submitandconfirme(orderNumber) {
            return this.$axios.put(`/interfaceApi/production/productorder/submitandconfirme/${orderNumber}`);
        },

        // 必填验证
        requiredValidation() {
            let verificationResults = true;
            for (const k in this.formRequired) {
                if ((this.formData[k] || '') === '') {
                    this.$message({
                        message: this.formRequired[k].message,
                        type: 'warning',
                    });
                    verificationResults = false;
                    return false;
                }
            }
            return verificationResults;
        },
    },
};
</script>
<style lang="stylus" scoped>
.orderApplication
    height 100%
    .pageContent
        height calc(100% - 0.8rem)
        margin-bottom 0.2rem
        overflow-y auto
        padding 0.2rem
        &.fomeSee
            height 100%
        .fomeStyle_dy
            table
                th
                    width 2.2rem
                    &:before
                        color red
                td
                    width 5rem
            .el-date-editor,.el-select
                width 100% !important
            .el-radio-group
                margin-left 0.14rem
            .slump
                .el-input
                    width calc((100% - 0.4rem)/2)
                >span
                    display inline-block
                    width 0.4rem
                    text-align center
            >>>.el-input
                &.is-disabled
                    .el-input__inner
                        color #333
    footer
        text-align center
        .el-button
            width 2.2rem
            height 0.4rem
            margin 0 0.3rem
            font-size 0.16rem
</style>
